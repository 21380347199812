import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useParams, Link } from 'react-router-dom';
import { Button } from 'antd';



const SchedulePage = () => {
  const { serviceName } = useParams();
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timezone, setTimezone] = useState('IST');
  const [showAllSessions, setShowAllSessions] = useState(false);
  const [serviceDetailsVisible, setServiceDetailsVisible] = useState(false);
  const [showAllButton, setShowAllButton] = useState(true);
  const [selectedSlotIndex, setSelectedSlotIndex] = useState(null);
  const [formattedDate, setFormattedDate] = useState('');
  const [timeSlots, setTimeSlots] = useState([]);

  useEffect(() => {
    document.title ='Time-Solts-Ekamida'
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    setFormattedDate(selectedDate.toLocaleDateString(undefined, options));
  }, [selectedDate]);

  useEffect(() => {
    fetchTimeSlots();
  }, [selectedDate, timezone]);

  const fetchTimeSlots = async () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    const response = await fetch(`http://localhost:8000/api/timeslots/?date=${dateStr}&timezone=${timezone}`);
    const data = await response.json();
    setTimeSlots(data);
  };

  const handleTimeSelection = (time, index) => {
    setSelectedTime(time);
    setSelectedSlotIndex(index);
  };

  const handleDateChange = (date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0); // Reset time part to midnight
    setSelectedDate(newDate);
    setShowAllSessions(false);
    setShowAllButton(true);
    setSelectedTime(''); // Reset selected time when date changes
    setSelectedSlotIndex(null); // Reset selected slot index when date changes
  };

  const handleTimezoneChange = (newTimezone) => {
    setTimezone(newTimezone);
    setShowAllSessions(false);
    setShowAllButton(true);
  };

  const handleShowAllSessions = () => {
    setShowAllSessions(true);
    setShowAllButton(false);
  };

  const handleNextAvailability = () => {
    const nextDate = new Date(selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    nextDate.setHours(0, 0, 0, 0); // Reset time part to midnight
    setSelectedDate(nextDate);
    setShowAllSessions(false);
    setShowAllButton(true);
    setSelectedTime(''); // Reset selected time when date changes
  };

  const toggleServiceDetails = () => {
    setServiceDetailsVisible(!serviceDetailsVisible);
  };

  const handleToggleTimezone = () => {
    setTimezone((prevTimezone) => (prevTimezone === 'BST' ? 'IST' : 'BST'));
  };

  const renderTimeSlots = () => {
    if (timeSlots.length === 0) {
      return (
        <div>
          <p>No availability for the selected date.</p>
          {!showAllSessions && <button onClick={handleNextAvailability}>Check Next Availability</button>}
        </div>
      );
    }

    const slotsToShow = showAllSessions ? timeSlots : timeSlots.slice(0, 10);
    return slotsToShow.map((slot, index) => (
      <li key={slot.id}>
        <button
          className={selectedSlotIndex === index ? 'selected' : ''}
          onClick={() => handleTimeSelection(slot.time, index)}
        >
          {slot.time}
        </button>
      </li>
    ));
  };

  return (
    <div className="booking-modal">
      <Link to={'/ServicePage'}><Button>Back</Button></Link>
      <h1>{serviceName}</h1>
      <p>Check out our availability and book the date and time that works for you</p>
      <div className="horizontal-container">
        <div className="calendar-wrapper">
          <h3>Select a Date</h3>
          <Calendar onChange={handleDateChange} value={selectedDate} />
        </div>
        <div className="time-slots-wrapper">
          <div className="timezone-wrapper">
            <select value={timezone} onChange={(e) => handleTimezoneChange(e.target.value)}>
              <option value="IST">India Standard Time (GMT+5:30)</option>
              <option value="BST">British Summer Time (GMT+1)</option>
            </select>
            <p>{formattedDate}</p>
          </div>
          <ul className="time-slots">
            {renderTimeSlots()}
          </ul>
          {timeSlots.length > 0 && !showAllSessions && <button onClick={handleShowAllSessions}>Show All Sessions</button>}
        </div>
        <div className="service-details-wrapper">
          <button className="accordion-button" onClick={toggleServiceDetails}>
            Service Details
            {serviceDetailsVisible ? <span className="arrow">&#9650;</span> : <span className="arrow">&#9660;</span>}
          </button>
          {serviceDetailsVisible && (
            <div className="service-details-content">
              <p>{serviceName}</p>
              <p>{selectedDate.toLocaleDateString()} at {selectedTime}</p>
              {/* Additional details such as location, duration, price, etc. */}
            </div>
          )}
          <Link to={`/booking/${serviceName}?date=${selectedDate.toISOString().split('T')[0]}&time=${selectedTime}`}>
            <button className='next-button' disabled={!selectedTime}>Next</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SchedulePage;
