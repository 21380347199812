import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Button } from 'antd';
import axios from 'axios';

const BookingForm = () => {
  const { serviceName } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const date = queryParams.get('date');
  const time = queryParams.get('time');
  const name = queryParams.get('name');

  const [serviceId, setServiceId] = useState(null);
  const [serviceDetailsVisible, setServiceDetailsVisible] = useState(false);
  const [msg, setMsg] = useState('');
  const [clientDetails, setClientDetails] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    smsReminder: false,
  });

  const [validationErrors, setValidationErrors] = useState({
    name: false,
    email: false,
    phone: false,
    message: false,
  });

  const toggleServiceDetails = () => {
    setServiceDetailsVisible(!serviceDetailsVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!serviceId) {
      console.error('Service ID is missing');
      return;
    }

    const formattedDate = new Date(date).toISOString().split('T')[0];

    const bookingData = {
      client_name: clientDetails.name,
      service: serviceId, // Ensure serviceId is an integer
      date: formattedDate,
      time: time,
      client_phone: clientDetails.phone,
      client_email: clientDetails.email,
    };

    try {
      // Send POST request to Django backend
      const response = await axios.post('http://localhost:8000/api/booking/', bookingData);
      console.log('Booking created:', response.data); // Log the response data if needed

      // Redirect to the next page if all required fields are filled
      window.location.href = `/bookingform/${serviceName}?date=${date}&time=${time}&name=${clientDetails.name}`;
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error.response.data);
    }

    const Data = {
      client_name: clientDetails.name,
      booking_date: formattedDate,
      booking_time: time,
      service_name: serviceName,
      email: clientDetails.email,
      phone: clientDetails.phone,
    };

    try {
      // Send POST request to Django backend
      const response = await axios.post("http://localhost:8000/booking/api/create_booking/", Data);
      console.log(response.data); // Log the response data if needed
      // Redirect to the next page if all required fields are filled
      window.location.href = `/bookingform/${serviceName}?date=${date}&time=${time}&name=${clientDetails.name}`;
    } catch (error) {
      // Handle errors
      console.error('Error submitting data:', error);
    }

    await axios.post("http://localhost:5000/users/", clientDetails)
      .then(response => setMsg(response.data.respMesg));

    // Check if all required fields are filled
    const errors = {};
    if (!clientDetails.name) {
      errors.name = true;
    }
    if (!clientDetails.email) {
      errors.email = true;
    }
    if (!clientDetails.phone) {
      errors.phone = true;
    }
    if (!clientDetails.message) {
      errors.message = true;
    }

    if (Object.keys(errors).length > 0) {
      // Update state to show validation errors
      setValidationErrors(errors);
    } else {
      // Clear validation errors
      setValidationErrors({});

      // Submit form data to the server or handle it as required
      console.log(clientDetails);

      // Redirect to the next page if all required fields are filled
      window.location.href = `/bookingform/${serviceName}?date=${date}&time=${time}&name=${clientDetails.name}`;
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setClientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: type === 'checkbox' ? checked : value,
    }));

    if (name === 'name') {
      setClientDetails((prevDetails) => ({
        ...prevDetails,
        text: `Dear ${value}, your booking for ${serviceName} on ${date} at ${time} has been confirmed.`,
      }));
    }

    // Clear validation error for the changed field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  useEffect(() => {
    document.title ='Booking-Form/Ekamvida'
    // Fetch serviceId based on serviceName from backend
    const fetchServiceId = async () => {
      try {
        const response = await axios.get(`http://localhost:8000/api/services/?name=${serviceName}`);
        if (response.data.length > 0) {
          setServiceId(response.data[0].id);
        }
      } catch (error) {
        console.error('Error fetching service ID:', error);
      }
    };

    fetchServiceId();
  }, [serviceName]);

  return (
    <div className="booking-form-container">
    <Link to={`/schedule/${serviceName}/`}>
      <Button>Back</Button>
    </Link>
      <form className="booking-form" onSubmit={handleSubmit}>
        <h2>Client Details</h2>
        <p>Tell us a bit about yourself</p>
        <p>
          Already have an account? <Link to="/login">Log In</Link> for faster booking.
        </p>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={clientDetails.name}
            onChange={handleChange}
            required
          />
          {validationErrors.name && <span className="error-message">Name is required.</span>}
        </div>

        <div className="form-group">
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={clientDetails.email}
            onChange={handleChange}
            required
          />
          {validationErrors.email && <span className="error-message">Email is required.</span>}
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone *</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={clientDetails.phone}
            onChange={handleChange}
            required
          />
          {validationErrors.phone && <span className="error-message">Phone is required.</span>}
        </div>

        <div className="form-group">
          <label htmlFor="smsReminder">
            <input
              type="checkbox"
              id="smsReminder"
              name="smsReminder"
              checked={clientDetails.smsReminder}
              onChange={handleChange}
            />
            I want to receive an SMS reminder 24 hours before this session starts
          </label>
        </div>

        <div className="form-group">
          <label htmlFor="message">Add Your Message *</label>
          <textarea
            type="text"
            id="message"
            name="message"
            value={clientDetails.message}
            onChange={handleChange}
            required
          />
          {validationErrors.message && <span className="error-message">Message is required.</span>}
        </div>
        <button type="submit">Book Now</button>
      </form>
      <div className="Booking-details-wrapper">
        <button className="accordion-button" onClick={toggleServiceDetails}>
          Booking Details
          {serviceDetailsVisible ? <span className="arrow">&#9650;</span> : <span className="arrow">&#9660;</span>}
        </button>
        {serviceDetailsVisible && (
          <div className="service-details-content">
            <p>{serviceName}</p>
            <p>
              {date} at {time}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingForm;
