import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';
import './ServicePage.css'; // Import the CSS file
import Header from './Header';


const ServicePage = () => {
  const [services, setServices] = useState([]);
    // Create a state to manage which service's info-section is open
    const [openService, setOpenService] = useState(null);

    const toggleReadMore = (id) => {
      // Toggle the openService state
      if (openService === id) {
        setOpenService(null); // Close if it's already open
      } else {
        setOpenService(id); // Open the selected service
      }
    };


  useEffect(() => {
    document.title = "Service-Ekamvida"
    axios.get('http://localhost:8000/api/services/')
      .then(response => {
        setServices(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the services!', error);
      });
  }, []);

  return (
    <div>
      <Navbar/> 
      <div className="hero">
        <div className="hero-text">
        <div class="logo4">
            <img src="images/background-2.svg" alt="Logo"/>
        </div>
          <h1>Group Yoga Classes</h1>
        </div>
      </div>
      <div className="container-1">
      <div className="left-side">
        <h2>Important Information</h2>
        <p>All our yoga classes are conducted in <span>English.</span></p>
        <p>
          Class bookings and session updates are <span>conducted through our WhatsApp group -</span> whether you’re a new member of our community, or a returning yogi, please contact us to join.
        </p>
        <p>
          <span>Class locations vary for each session - </span><br />
          the most updated information can be found in our WhatsApp group, please contact us to join.
        </p>
        <a href="#" className="button-left">
          Join us on WhatsApp <img src='images/WhatsApp.svg' alt="WhatsApp" />
        </a>
        <div className="decorative-right-side">
          <img src='images/background-2.svg' alt="Decorative element" />
        </div>
      </div>

      <div className="right-side">
        <h3>We offer yoga classes for active flow, mindful meditation, and specialty practices:</h3>
      {services.map(service => (
        <div key={service.id} className={`yoga-class ${openService === service.id ? 'expanded' : ''}`}>
          <img src={service.image} alt={service.service_name} />
          <div className="yoga-info">
            <h2>{service.service_name}</h2>
            <p>{service.description}</p>
          </div>
          <div className="button-class">
            <Link to={`/schedule/${service.service_name}`} className="right-side-button">
              Book a class
            </Link>
            <a className="read-more-button" onClick={() => toggleReadMore(service.id)}>
            {openService === service.id ? 'Read less' : 'Read more'}
            <img
              src={openService === service.id ? 'images/minus.svg' : 'images/plus.svg'} // Toggle icon based on state
              style={{
                width: '20px',
                height: '20px',
              }}
              alt="toggle-icon"
            />
          </a>
          </div>

          
          
          {openService === service.id && (
            <div className="info-section">
              <div className="column">
                <h3>What to Expect</h3>
                <p>Vinyasa is a more physically challenging practice, focusing on flow of movement, so expect to get your heart pumping.</p>
              </div>
              <div className="column">
                <h3>Benefits</h3>
                <ul>
                  <li>Builds lean muscles throughout the whole body</li>
                  <li>Calms and purifies the mind and body together through intense focus</li>
                </ul>
              </div>
              <div className="column">
                <h3>Suitable For</h3>
                <ul>
                  <li>Constant movement is good for improving posture</li>
                  <li>Classes focus more on fast pacing over accuracy; best suited to experienced yogis</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      ))}

      
    </div>

      <Outlet />
    </div>
    <Header />

    </div>
  );
};

export default ServicePage;
