import React, { useEffect, useState, useRef } from 'react';
import './Navbar.css';
import { useAuth } from './AuthContext';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';




const Navbar = () => {
    const { isAuthenticated, logout, user } = useAuth();
    const [showAccountMenu, setShowAccountMenu] = useState(false);
    const accountMenuRef = useRef(null);
    const [openDropdown, setOpenDropdown] = useState(null);




    const handleAccountMenuClick = () => {
        setShowAccountMenu(!showAccountMenu);
      };
    
      const handleOutsideClick = (e) => {
        if (accountMenuRef.current && !accountMenuRef.current.contains(e.target)) {
          setShowAccountMenu(false);
        }
      };
    
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (!event.target.closest('.dropdown')) {
            setOpenDropdown(prevDropdown => {
              if (prevDropdown) {
                document.getElementById(`${prevDropdown}-arrow`).style.transform = 'rotate(0deg)';
              }
              return null;
            });
          }
        };
      
        document.addEventListener('click', handleClickOutside);
      
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);
    
      const toggleDropdown = (dropdownName) => {
        setOpenDropdown(prevDropdown => {
          if (prevDropdown === dropdownName) {
            document.getElementById(`${dropdownName}-arrow`).style.transform = 'rotate(0deg)';
            return null;
          } else {
            if (prevDropdown) {
              document.getElementById(`${prevDropdown}-arrow`).style.transform = 'rotate(0deg)';
            }
            document.getElementById(`${dropdownName}-arrow`).style.transform = 'rotate(180deg)';
            return dropdownName;
          }
        });
      };
    
    
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (!event.target.closest('.dropdown')) {
            setOpenDropdown(null);
          }
        };
      
        document.addEventListener('click', handleClickOutside);
      
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);
    
  return (
    <nav id="navbar">
        <div class="navbar">
            <div class="logo">
            <a href={"/"}><img src="\images\bgTransp-logoBlack.png" alt="Ekamvida Yoga" /></a>
            </div>
            <div class="menu">
                <ul>
                <li id="Yoga-Classes" className="dropdown-first">
              <a href="/" onClick={(e) => {e.preventDefault(); toggleDropdown('yoga');}} >
                Yoga Classes <span className="arrow" id="yoga-arrow">&#9662;</span>
              </a>
              <div className={`dropdown-content-first ${openDropdown === 'yoga' ? 'show' : ''}`}>
                <Link to="/ServicePage">Group Classes</Link>
                <a href="/privateclass">Private Classes</a>
              </div>
            </li>
                    <li><a href="/Schedule">Schedule</a></li>
                    <li><a href="/price">Pricing & Packages</a></li>
                    <li><a href="/Contactus">Contact Us</a></li>
                    <li><a href="/Faqs">FAQs</a></li>
                    <li className="dropdown-first">
                    <a href="#" onClick={(e) => {e.preventDefault(); toggleDropdown('explore');}} >
                        Explore More <span className="arrow" id="explore-arrow">&#9662;</span>
                    </a>
                    <div className={`dropdown-content-first ${openDropdown === 'explore' ? 'show' : ''}`}>
                        <a href="/Events">Events</a>
                        <a href="/Blog">Blog</a>
                        <a href="#">About Us</a>
                    </div>
                    </li>
                </ul>
            </div>
            <div className="buttons">
            {isAuthenticated ? (
              <>
                <button onClick={handleAccountMenuClick} className="login-register1">
                <FontAwesomeIcon icon={faCircleUser} />
                  My Account
                </button>
                {showAccountMenu && (
                  <div ref={accountMenuRef} className="account-menu">
                    <Link to="/profile" onClick={() => setShowAccountMenu(false)}>Profile</Link>
                    <Link to="/orders" onClick={() => setShowAccountMenu(false)}>Orders</Link>
                    <button onClick={logout}>Logout</button>
                  </div>
                )}
              </>
            ) : (
              <Link to="/login">
                <button className="login-register-nav">Login/Register</button>
              </Link>
            )}
            <button className="join-classes-nav">Join our classes</button>
          </div>
        </div>
    </nav>
    
    
  )
}

export default Navbar
