import React, { useEffect, useState, useRef } from 'react';
import './Home.css';
import { useAuth } from './AuthContext'; // Import the useAuth hook
import { Link } from 'react-router-dom';
import './TestimonialSection.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';





const API_URL = 'http://localhost:8000/api/testimonials/';

const Home = () => {
  const { isAuthenticated, logout, user } = useAuth(); // Assuming `user` is part of the auth context
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [form, setForm] = useState({ name: '', message: '' });
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const accountMenuRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(null);

  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = (dropdownName) => {
    setOpenDropdown(dropdownName);
    setIsHovered(true);
  };
  
  const handleMouseLeave = () => {
    setOpenDropdown(null);
    setIsHovered(false);
  };






  useEffect(() => {
    fetchTestimonials();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [testimonials.length]);

  const fetchTestimonials = async () => {
    try {
      const response = await axios.get(API_URL);
      setTestimonials(response.data);
    } catch (error) {
      console.error('Error fetching testimonials:', error);
    }
  };

  const handlePaginationClick = (index) => {
    setCurrentIndex(index);
  };

  const handleShareClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(API_URL, form);
      setForm({ name: '', message: '' });
      closeModal();
      fetchTestimonials();
    } catch (error) {
      console.error('Error submitting testimonial:', error);
    }
  };

  const handleAccountMenuClick = () => {
    setShowAccountMenu(!showAccountMenu);
  };

  const handleOutsideClick = (e) => {
    if (accountMenuRef.current && !accountMenuRef.current.contains(e.target)) {
      setShowAccountMenu(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-first')) {
        setOpenDropdown(prevDropdown => {
          if (prevDropdown) {
            document.getElementById(`${prevDropdown}-arrow`).style.transform = 'rotate(0deg)';
          }
          return null;
        });
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(prevDropdown => {
      if (prevDropdown === dropdownName) {
        document.getElementById(`${dropdownName}-arrow`).style.transform = 'rotate(0deg)';
        return null;
      } else {
        if (prevDropdown) {
          document.getElementById(`${prevDropdown}-arrow`).style.transform = 'rotate(0deg)';
        }
        document.getElementById(`${dropdownName}-arrow`).style.transform = 'rotate(180deg)';
        return dropdownName;
      }
    });
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-first')) {
        setOpenDropdown(null);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Select elements
      const navbar = document.getElementById('navbar-first');
      const links = document.querySelectorAll('.menu-first a');
      const loginRegisterButton = document.querySelector('.login-register-first');
      const defaultLogo = document.getElementById('logo-default-first');
      const scrollLogo = document.getElementById('logo-scroll-first');
  
      // Null checks
      if (navbar) {
        if (window.scrollY > 50) {
          navbar.classList.add('scrolled');
        } else {
          navbar.classList.remove('scrolled');
        }
      }
  
      if (links.length > 0) {
        links.forEach(link => {
          if (window.scrollY > 50) {
            link.classList.add('scrolled-link');
          } else {
            link.classList.remove('scrolled-link');
          }
        });
      }
  
      if (loginRegisterButton) {
        if (window.scrollY > 50) {
          loginRegisterButton.classList.add('scrolled-button');
        } else {
          loginRegisterButton.classList.remove('scrolled-button');
        }
      }
  
      if (defaultLogo && scrollLogo) {
        if (window.scrollY > 50) {
          defaultLogo.style.display = 'none';
          scrollLogo.style.display = 'block';
        } else {
          defaultLogo.style.display = 'block';
          scrollLogo.style.display = 'none';
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  useEffect(() => {
    const classCards = document.querySelectorAll('.class-card');
    
    classCards.forEach(card => {
      const img = card.querySelector('img');
      const button = card.querySelector('button');
      const header = card.querySelector('h3');
  
      const onMouseEnter = () => {
        img.style.transform = 'scale(1.1)';
        button.style.borderColor = '#7B7092';
        button.style.borderWidth = '3px';  // Set border width to 3px on hover
        header.style.color = '#7B7092';
      };
  
      const onMouseLeave = () => {
        img.style.transform = 'scale(1)';
        button.style.borderColor = 'initial'; // Reset border color
        button.style.borderWidth = '1px';  // Reset border width to the default
        header.style.color = 'initial';
      };
  
      img.addEventListener('mouseenter', onMouseEnter);
      img.addEventListener('mouseleave', onMouseLeave);
      button.addEventListener('mouseenter', onMouseEnter);
      button.addEventListener('mouseleave', onMouseLeave);
  
      return () => {
        img.removeEventListener('mouseenter', onMouseEnter);
        img.removeEventListener('mouseleave', onMouseLeave);
        button.removeEventListener('mouseenter', onMouseEnter);
        button.removeEventListener('mouseleave', onMouseLeave);
      };
    });
  }, []);
  
  

  useEffect(() => {
    const slider = document.querySelector('.classes');
    const prevBtn = document.getElementById('prev-btn');
    const nextBtn = document.getElementById('next-btn');
    let isDown = false;
    let startX;
    let scrollLeft;

    if (!slider) {
      console.error('Slider element not found');
      return;
    }

    const handleMouseDown = (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const handleMouseLeave = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const handleMouseUp = () => {
      isDown = false;
      slider.classList.remove('active');
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 2;
      slider.scrollLeft = scrollLeft - walk;
    };

    slider.addEventListener('mousedown', handleMouseDown);
    slider.addEventListener('mouseleave', handleMouseLeave);
    slider.addEventListener('mouseup', handleMouseUp);
    slider.addEventListener('mousemove', handleMouseMove);

    const cardWidth = slider.querySelector('.class-card').offsetWidth;
    const visibleCards = Math.floor(slider.clientWidth / cardWidth);

    const handlePrevClick = () => {
      slider.scrollBy({
        left: -cardWidth * visibleCards,
        behavior: 'smooth'
      });
    };

    const handleNextClick = () => {
      slider.scrollBy({
        left: cardWidth * visibleCards,
        behavior: 'smooth'
      });
    };

    prevBtn.addEventListener('click', handlePrevClick);
    nextBtn.addEventListener('click', handleNextClick);

    const updateButtonStates = () => {
      prevBtn.disabled = slider.scrollLeft <= 0;
      nextBtn.disabled = slider.scrollLeft + slider.clientWidth >= slider.scrollWidth;
    };

    slider.addEventListener('scroll', updateButtonStates);
    window.addEventListener('resize', updateButtonStates);

    updateButtonStates();

    return () => {
      slider.removeEventListener('mousedown', handleMouseDown);
      slider.removeEventListener('mouseleave', handleMouseLeave);
      slider.removeEventListener('mouseup', handleMouseUp);
      slider.removeEventListener('mousemove', handleMouseMove);
      prevBtn.removeEventListener('click', handlePrevClick);
      nextBtn.removeEventListener('click', handleNextClick);
      slider.removeEventListener('scroll', updateButtonStates);
      window.removeEventListener('resize', updateButtonStates);
    };
  }, []);

  useEffect(() => {
    const section = document.querySelector('.instagram-section');
    const feed = document.querySelector('.instagram-feed');
    const prevButton = document.querySelector('.nav-button.prev');
    const nextButton = document.querySelector('.nav-button.next');
    const scrollButton = document.getElementById('scrollButton');
    let isAtStart = true;
    let isMouseDown = false;
    let startX;
    let scrollLeft;

    const handleScrollButtonClick = () => {
      const feedWidth = feed.scrollWidth;
      const containerWidth = feed.clientWidth;

      if (isAtStart) {
        // Scroll to end
        feed.scrollTo({
          left: feedWidth - containerWidth,
          behavior: 'smooth'
        });
        scrollButton.innerHTML = '&lt;';
        scrollButton.classList.add('left');
      } else {
        // Scroll to start
        feed.scrollTo({
          left: 0,
          behavior: 'smooth'
        });
        scrollButton.innerHTML = '&gt;';
        scrollButton.classList.remove('left');
      }

      isAtStart = !isAtStart;
    };

    const handlePrevButtonClick = () => {
      feed.scrollBy({
        left: -300,
        behavior: 'smooth'
      });
    };

    const handleNextButtonClick = () => {
      feed.scrollBy({
        left: 300,
        behavior: 'smooth'
      });
    };

    const handleMouseEnter = () => {
      feed.style.cursor = 'grab';
    };

    const handleMouseLeave = () => {
      feed.style.cursor = 'default';
    };

    const handleMouseDown = (e) => {
      isMouseDown = true;
      feed.style.cursor = 'grabbing';
      startX = e.pageX - feed.offsetLeft;
      scrollLeft = feed.scrollLeft;
    };

    const handleMouseUp = () => {
      isMouseDown = false;
      feed.style.cursor = 'grab';
    };

    const handleMouseMove = (e) => {
      if (!isMouseDown) return;
      e.preventDefault();
      const x = e.pageX - feed.offsetLeft;
      const walk = (x - startX) * 2; // Adjust sliding speed
      feed.scrollLeft = scrollLeft - walk;
    };

    scrollButton.addEventListener('click', handleScrollButtonClick);
    if (prevButton) {
      prevButton.addEventListener('click', handlePrevButtonClick);
    }
    if (nextButton) {
      nextButton.addEventListener('click', handleNextButtonClick);
    }
    feed.addEventListener('mouseenter', handleMouseEnter);
    feed.addEventListener('mouseleave', handleMouseLeave);
    feed.addEventListener('mousedown', handleMouseDown);
    feed.addEventListener('mouseup', handleMouseUp);
    feed.addEventListener('mousemove', handleMouseMove);

    // Log to console for debugging
    console.log('Prev button:', prevButton);
    console.log('Next button:', nextButton);

    return () => {
      scrollButton.removeEventListener('click', handleScrollButtonClick);
      if (prevButton) {
        prevButton.removeEventListener('click', handlePrevButtonClick);
      }
      if (nextButton) {
        nextButton.removeEventListener('click', handleNextButtonClick);
      }
      feed.removeEventListener('mouseenter', handleMouseEnter);
      feed.removeEventListener('mouseleave', handleMouseLeave);
      feed.removeEventListener('mousedown', handleMouseDown);
      feed.removeEventListener('mouseup', handleMouseUp);
      feed.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <>
    <nav id="navbar-first" className={isScrolled || isHovered ? 'scrolled' : ''}>
      <div className="navbar-first">
        <div className="logo-first">
          <Link to="/">
            <img
              src="images/bgTransp-logoWhite.png"
              alt="Ekamvida Yoga"
              id="logo-default-first"
              style={{ display: isScrolled || isHovered ? 'none' : 'block' }}
            />
            <img
              src="images/bgTransp-logoBlack.png"
              alt="Ekamvida Yoga"
              id="logo-scroll-first"
              style={{ display: isScrolled || isHovered ? 'block' : 'none' }}
            />
          </Link>
        </div>
        <div className="menu-first">
          <ul>
            <li
              id="Yoga-Classes"
              className="dropdown-first"
              onMouseEnter={() => handleMouseEnter('yoga')}
              onMouseLeave={handleMouseLeave}
            >
              <a href="/" onClick={(e) => { e.preventDefault(); toggleDropdown('yoga'); }}>
                Yoga Classes <span className="arrow" id="yoga-arrow">&#9662;</span>
              </a>
              <div className={`dropdown-content-first ${openDropdown === 'yoga' ? 'show' : ''}`}>
                <Link to="/ServicePage">Group Classes</Link>
                <a href="/privateclass">Private Classes</a>
              </div>
            </li>
            <li><a href="/Schedule">Schedule</a></li>
            <li><a href="/price">Pricing & Packages</a></li>
            <li><a href="/Contactus">Contact Us</a></li>
            <li><a href="/Faqs">FAQs</a></li>
            <li
              className="dropdown-first"
              onMouseEnter={() => handleMouseEnter('explore')}
              onMouseLeave={handleMouseLeave}
            >
              <a href="/" onClick={(e) => { e.preventDefault(); toggleDropdown('explore'); }}>
                Explore More <span className="arrow" id="explore-arrow">&#9662;</span>
              </a>
              <div className={`dropdown-content-first ${openDropdown === 'explore' ? 'show' : ''}`}>
                <a href="/Events">Events</a>
                <a href="/Blog">Blog</a>
                <a href="#">About Us</a>
              </div>
            </li>
          </ul>
        </div>
        <div className="buttons-first">
          {isAuthenticated ? (
            <>
              <button onClick={handleAccountMenuClick} className="login-register-first">
                <FontAwesomeIcon icon={faCircleUser} />
                My Account
              </button>
              {showAccountMenu && (
                <div ref={accountMenuRef} className="account-menu">
                  <Link to="/profile" onClick={() => setShowAccountMenu(false)}>Profile</Link>
                  <Link to="/orders" onClick={() => setShowAccountMenu(false)}>Orders</Link>
                  <button onClick={logout}>Logout</button>
                </div>
              )}
            </>
          ) : (
            <Link to="/login">
              <button className="login-register-first">Login/Register</button>
            </Link>
          )}
          <button className="join-classes-first">Join our classes</button>
        </div>
      </div>
    </nav>

            <div className="hero-first">
              <div className="hero-text-first">
                <h1>Your body is your temple</h1>
                <p>Begin your journey and unlock a new level of feeling good</p>
                <a href="#">Check out our yoga classes <img className='ct-arrow-right' src='images/arrow-right.svg' /></a>
                <div class="logo3">
            <img src="images/logo3.svg" alt="Logo"/>
        </div>
              </div>
            </div>
            <div className="section-first">
              <div className="section-content-first">
                <div className="text-container-first">
                  <div className="left-content-first">
                    <h1>Yoga for all,<br />from beginner <br/> to advanced</h1>
                  </div>
                  <div className="right-content-first">
                    <p>Health and wellness is a journey of the physical, emotional, mental, and spiritual. Finding a unique space to focus on yourself, inwardly and outwardly, can be challenging.</p>
                    <p>GLYoga is a yoga and wellness community welcoming people from any background to join and begin their journey to a healthier, more fulfilled lifestyle. Led by us -- Georgia and Krishna, a dynamic duo of yoga instructors -- our mission is to create a space that promotes respect, inclusivity, and ultimately growth.</p>
                    <a href="#" className="ct-button">See what classes we offer <img className='ct-arrow-right' src='images/arrow-right.svg' /></a>
                  </div>
                </div>
                <div className="decorative-element-left">
                  <img src='images/background-1 (2).svg' alt="Decorative element" />
                </div>

                <div className="decorative-element-right">
                  <img src='images/background-1 (2).svg' alt="Decorative element" />
                </div>
              </div>
              </div>
           
            <div className="class-moving">
              <div className="header">
                <h1>Classes that get you moving</h1>
                <p>
                  Yoga classes we offer
                  <span>
                  <button className="nav-btn" id="prev-btn"><img className='arrow-right' src='images/arrow-left.svg' /> </button>
                  <button className="nav-btn" id="next-btn"><img className='arrow-right' src='images/arrow-right.svg' /></button>
                  </span>

                </p>
              </div>
              <div className="classes">
              <div className="decorative-element-left">
                  <img src='images/background-1 (2).svg' alt="Decorative element" />
                </div>

                <div className="decorative-element-right">
                  <img src='images/background-1 (2).svg' alt="Decorative element" />
                </div>
                <div className="class-card">
                  <div className='class-card-header'>
                  <h3>Hatha Yoga Classes</h3>
                  <p>Hatha yoga is a branch of yoga which uses physical techniques and movement to promote energy and strength.</p>
                  </div>

                  <img src="images/Hath-Yoga.png" alt="Hatha Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Yin Yoga Classes</h3>
                  <p>Yin yoga is a slow-paced style of yoga aimed at relaxation, derived from principles of traditional Chinese medicinal practice.</p>
                  </div>
                  <img src="images/vinyasan-yoga.png" alt="Yin Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Vinyasa Yoga Classes</h3>
                  <p>A dynamic and fast-paced style of yoga that will test your strength and stamina. This physically challenging class will make you work up a sweat. </p>
                  </div>
                  <img src="images/pexels-yan-krukau-8436616 1.png" alt="Yin Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Yin-Yang Yoga Classes</h3>
                  <p>30 minutes of dynamic heating flow to stimulate sun energy (yang) followed by 30 minutes of cooling yin postures to relax and restore,  </p>
                  </div>
                  <img src="images/yin-yan-yoga.jpg" alt="Yin Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Guided Meditation Sessions</h3>
                  <p>Meditation helps us to become mindful and present. Our guided sessions will help you to find peace and clarity. </p>
                  </div>
                  <img src="images/Guidded-meditations.jpg" alt="Yin Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Pranayama Practice</h3>
                  <p>In this class, our experienced instructors will help you develop breath awareness as they guide you through various breathing techniques  </p>
                  </div>
                  <img src="images/paranyam.jpg" alt="Yin Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>

                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Prenatal Yoga</h3>
                  <p>The primary focus during pregnancy is relaxation and preparation for birth. This class involves gentle stretches, breathwork, meditation. </p>
                  </div>
                  <img src="images/prental.jpg" alt="Vinyasa Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Yoga Therapy</h3>
                  <p>Yoga can be used as a therapeutic and holistic way of healing from illnesses & injuries. Recommended: 1-on-1 practice</p>
                  </div>
                  <img src="images/Yoga-therpay.jpg" alt="Vinyasa Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Yoga Wheel Classes</h3>
                  <p>a fun and dynamic class using the yoga wheel prop to support and challenge the poses. Emphasis on spinal rolling.</p>
                  </div>
                  <img src="images/wheel-02.jpg" alt="Vinyasa Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
                <div className="class-card">
                <div className='class-card-header'>
                  <h3>Inversion Practice</h3>
                  <p>This class will help you to gradually build up the required body strength and confidence to master inversions like headstand, shoulder stand. </p>
                  </div>
                  <img src="images/handstand-02.jpg" alt="Vinyasa Yoga" />
                  <button>More about this class
                    <span><img className='arrow-right' src='images/arrow-right.svg' /></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="chat-section">
              <div class="chat-container">
                <div class="chat-text">
                  Not sure where to start? Get in touch with us to get the perfect suggestion for you
                </div>
                <a href="#" class="chat-button">
                  Chat with us <img className='arrow-right' src='images/arrow-right.svg' />
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4L20 12L12 20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 12H20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </a>
              </div>
            </div>
            <div class="container-grid">
            <div className="decorative-elements-left">
                  <img src='images/background-1 (2).svg' alt="Decorative element" />
                </div>

                <div className="decorative-elements-right">
                  <img src='images/background-1 (2).svg' alt="Decorative element" />
                </div>
            
              
        <header>
            <h1>Create your own space to grow</h1>
            <p>Embark on your wellness journey and start feeling like a brand new you -<br/>
               check out our yoga classes, retreats, events, and much more</p>
        </header>
        <div class="grid-g">
            <div class="card-g">
                <img src="images/halfmoon-03 (1).jpg" alt="All Classes"/>
                <div class="card-content-g">
                    <h2>All Classes</h2>
                    <p>Find the right yoga or wellness practice from the 10+ styles we offer</p>
                    <a href="#" class="btn-g">Check out our classes <img className='arrow-check' src='images/arrow-right.svg'/></a>
                </div>
            </div>
            <div class="card-g">
                <img src="images\cobra-03.jpg" alt="Schedules"/>
                <div class="card-content-g">
                    <h2>Schedules</h2>
                    <p>We display our class schedules online for smooth and simple booking</p>
                    <a href="#" class="btn-g">View our full schedule <img className='arrow-check' src='images/arrow-right.svg'/></a>
                </div>
            </div>
            <div class="card-g">
                <img src="images/wildthing-01.jpg" alt="Pricing & Packages"/>
                <div class="card-content-g">
                    <h2>Pricing & Packages</h2>
                    <p>Browse our pricing range, or speak to us for hyper-flexible package offers</p>
                    <a href="#" class="btn-g">See packages we offer <img className='arrow-check' src='images/arrow-right.svg'/></a>
                </div>
            </div>
            <div class="card-g">
                <img src="
                images/forearmstand-01.jpg" alt="Upcoming Events"/>
                <div class="card-content-g">
                    <h2>Upcoming Events</h2>
                    <p>We keep our community strong and thriving - come meet us at our events</p>
                    <a href="#" class="btn-g">Join our events <img className='arrow-check' src='images/arrow-right.svg'/></a>
                </div>
            </div>
        </div>
    </div>
    <div className="testimonial-container">
      <h3>Kind words from our community</h3>
      <div className="testimonial-slider" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-slide">
            <p>{testimonial.message}</p>
            <div className="author">- {testimonial.name}</div>
          </div>
        ))}
      </div>
      <div className="pagination">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? 'active' : ''}
            onClick={() => handlePaginationClick(index)}
          ></span>
        ))}
      </div>
      <div className="share-btn">
        <button onClick={handleShareClick}>Share your thoughts</button>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-btn" onClick={closeModal}>×</button>
            <h2>Share Your Thoughts</h2>
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input type="text" name="name" value={form.name} onChange={handleInputChange} required />
              </label>
              <label>
                Message:
                <textarea name="message" value={form.message} onChange={handleInputChange} required></textarea>
              </label>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
      <div class="instagram-section">
      <div className="decorative-instagram-left">
                  <img src='images/background-1 (2).svg' alt="Decorative element" />
                </div>

                <div className="decorative-instagram-right">
                  <img src='images/background-1 (2).svg' alt="Decorative element" />
                </div>
    <div className='instagram-header'>
    <h2>Check us out on Instagram</h2>
    <p>Follow us @georgia.kp.yoga</p>
    </div>

    <div class="instagram-feed">
        <div class="instagram-overlay">
            <img src="images/10.png" alt="Instagram Image 3"/>
            <div class="overlay">
                <div class="instagram-text">
                    <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}}/>
                    <p>@georgia.kp.yoga</p>
                </div>
            </div>
        </div>
        <div class="instagram-overlay">
            <img src="images/09.png" alt="Instagram Image 3"/>
            <div class="overlay">
                <div class="instagram-text">
                    <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}} />
                    <p>@georgia.kp.yoga</p>
                </div>
            </div>
        </div>
        <div class="instagram-overlay">
            <img src="https://s3-alpha-sig.figma.com/img/ac56/8ced/bbb0e814947956e5523bdeab9543b0b8?Expires=1727049600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DK6PbcHWJkn913-ef7~yRzDFTfRbuEZcKxHNSNRycrllvvvf5IO8GFWmYHTYj9~~T0GBA1soHGhNUcvtLr6cUIOID5nTHBjhnv~ZooRnL5SPt~FHlTa8bRi9Vvn8eOtS5alq2jSklxSzEFBPPCwRy15NFSqa3GWShW6pkh6v6z3XR7mr71hCMQ3Symp01zLnps2d-YGZZCI28AyrBnhNHmYUHdZMKtwxa2fp5rEmGe2p5iy9MOnXoRDLATR6xZ-6ImbNBx~CXNkIgO1W-no8c3gInFlvoIc0hON1n5U3HJqgK7PGp3L0Hb9bR5-fZ22LfOfpN~qBPCcoaqN6Yuficw__" alt="Instagram Image 3"/>
            <div class="overlay">
                <div class="instagram-text">
                    <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}} />
                    <p>@georgia.kp.yoga</p>
                </div>
            </div>
    </div>
    <div class="instagram-overlay">
        <img src="https://s3-alpha-sig.figma.com/img/b354/60af/091bd0bc0de1b3537bc40aafe2deb4c2?Expires=1727049600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Y1iMeLnvteL3zkES62uAE9~j8~Y9gCHJ-JlwTTNVBGY6qt7EZ02P37SpdBQT0EOhQs1QIdhESPxQScJxeh7BZ2bc-XXHKgyF68es9lu5RJTIMqHHlhjPD~Nl3Xukpi2FzXij82FoWj22Ci316ZgJK94dRS6P6W~hvmLyh0dMd1h1-6gO0x88s-0ejYX6iOUbFv9FbtFD3p1AgVE2SGH-VeQQWkNxgRejVb7P--iQubWOddw4UShLx7Jcpip7ERrYRl6rJn0ddlmtd2tkp1WhIBdRuoTKtAdgXetwz0LRL5uN89AbwFE4DJPilwCu54CWjJpBEycE7VMj-~vQyO9BFQ__" alt="Instagram Image 3"/>
        <div class="overlay">
            <div class="instagram-text">
                <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}}/>
                <p>@georgia.kp.yoga</p>
            </div>
        </div>
    </div>

    <div class="instagram-overlay">
        <img src="https://s3-alpha-sig.figma.com/img/cf88/051c/61828901a102e9109cf6851867788566?Expires=1727049600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=obcxNn03Ejo3NePZZgD5Rh6TWvvM-NSgggIDfuPJ6u23hWP2YYdJA~TQNsiznq1biUXbST9LsBp99PPSo9Cea4t8YGS6xPpOJ9mcwx~Izc94CU9Ow9njQqHM793d1VE-0se9gTvRj2pYQCQtMd5RzzutZUQyY3QTPKWSlaCfilbZZRlKR2F7EcA7v0bGDz2BsOT~aEvFZyHFrvvgHYhZG7tdOP4h2qsZVU5nb~p65M4P5hma1EYLa2C-k6H0wpOV7G81yGN6JaFQ3QNo7EpBbIZrugKhgIf2xArHEGC6VLks4u~yepB8LH30UROAyymXBYNsVKuSsmVA9oyDPcsR1Q__" alt="Instagram Image 3"/>
        <div class="overlay">
            <div class="instagram-text">
                <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}}/>
                <p>@georgia.kp.yoga</p>
            </div>
        </div>
    </div>
    <div class="instagram-overlay">
            <img src="https://s3-alpha-sig.figma.com/img/52b5/b84a/25ee413de33a123e0e8a0cf7dcdbc302?Expires=1727049600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=P4Qkm1K83stoUI6mRQ4rL5tIce414nI9K~LdWrIdVoW8G8PQvAfdm47oXbdKKkgenI4vhfQqpanyc~q6Cgg06ma2mcU8o6UCgTz-OnzohoZhx2g2BvyFkjwk1UVVW9bazAMyhT1FyyIeeyxaT23AtRweZ3z-qdbWNDMz-UMgSUEIjeI8Ik2b6ukPWc72FMZqovxrZEAH7hrrjrpYmJ8i~pHKZWpg6XIoqI0iUJlhL5IPQKL-GBXkqw7DpRMdIhfPDP8F2QihWD9868sW2wveQ-xZ9jXO85CBwYyq2xJ4Xub~yQKJkfdQhmclUQ0Oi~Z7g4E88lccqifji36tJMQHyA__" alt="Instagram Image 3"/>
            <div class="overlay">
                <div class="instagram-text">
                    <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}} />
                    <p>@georgia.kp.yoga</p>
                </div>
            </div>
        </div>
        <div class="instagram-overlay">
            <img src="https://s3-alpha-sig.figma.com/img/3dba/c8f8/77c522fb5ecc38f81c0d1aca42a41201?Expires=1727049600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kaQs4dS8gjw~YYyTLy41rBLvQWGRFf8XGlpPRXR4x3Y~xtUD4wRN8zjyAoQw5g7nBEYD8A2uhSWO9yvT0ETwxqH5QUxTOIz86p42QBJ6wOMZ8362xZxrVBxWeYYQmB63k46A1FL6GH3kMz3nvQb~UHDDVOYYSJUNKinqnSv9Fx39mRkSyUStpemLlS~Ue5si9BcepFvnoOgZEM008vyJYF0ZW3Gz5stka9Dp99CbVpc5vm6cDPjWZvwPAyy3hUUhvd5sD-ETWwe4sgtF~xtrhbIFEbzXfs9BAMD8ReOBrrXgS2itmMW~RbeLhpKgJimEEJQqcZGQgIkzWzoZsj81Iw__" alt="Instagram Image 3"/>
            <div class="overlay">
                <div class="instagram-text">
                    <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}} />
                    <p>@georgia.kp.yoga</p>
                </div>
            </div>
        </div>
        <div class="instagram-overlay">
            <img src="https://s3-alpha-sig.figma.com/img/48b9/60fe/73bf565abd80e5781f3faa4e99555604?Expires=1727049600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=W2SYmENcxlEUsPTSjy0OO4RxSo1LaHQjaX4Du4Amem7V-q7zYZV4lxTixiO8VuOVtnuVekTksKvrY9d6C52fAIh63p0Re5Bf8bhrRsLfsoLiSH80-hh9Pr53zyX0qqv3rH0-VcsHj9mbqY7wb9iZMnJmHhe2vaWA7Z1c8vroWSTTjP44kTj0THS3Ac5Dn7suN87VSMk~Tpt5CEZfelhhnvMo-Kxjw-shTolxBkrbbmDQIVibtfzK8tkLl61wcbSL3-LRNRCcCq22ycsMyv9gYHfjrIVGo8UBBHUf1Bc2ld3ppf8NAuROt1KptW-qRU2fPyVMf1pBozOJRQoi6iXWGw__" alt="Instagram Image 3"/>
            <div class="overlay">
                <div class="instagram-text">
                    <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}} />
                    <p>@georgia.kp.yoga</p>
                </div>
            </div>
        </div>
        <div class="instagram-overlay">
            <img src="https://s3-alpha-sig.figma.com/img/1340/5116/eac9fcd7f4d04c420539cac2f0bee47d?Expires=1727049600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=O5YIzIg1ZmK9du3HJa3rHpjRCeHiFMM5hMbks2iqm8wivkX-pSJPS8Q7~Bli7JPUzgknR1MulXO2B~1j4VdtrBaiXyUZc5liTZrr2OlH5sgRRqA4HFm~4MWM4jGkk6JwnRwKDGW3h4Euv6L4uRRVfYDKIIQeMqXF4W1sGlG8QeoiWMz62Vtul6~kjeHinirkhjLqkiR7UH6V20K5DqVawqp5M82hxBtTaHMoWXiUZwlxBfjbJZZkVl63DgW6Ym8faZs~3MXD0xayYdW2Xn5nGc-59Uk4ug4RLigMrurtOPRfWkAOSI9H2f1fIiKhcH0WjI~JBTzXQV5FfXJctFhJNA__" alt="Instagram Image 3"/>
            <div class="overlay">
                <div class="instagram-text">
                    <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}} />
                    <p>@georgia.kp.yoga</p>
                </div>
            </div>
        </div>
        {/* <div class="instagram-overlay">
            <img src="images/09.png" alt="Instagram Image 3"/>
            <div class="overlay">
                <div class="instagram-text">
                    <img src="images/style=Colour.svg" alt="Instagram Logo" style={{height:'50px', width:'50px'}} />
                    <p>@georgia.kp.yoga</p>
                </div>
            </div>
        </div> */}
    <div>
        <button class="nav-button" id="scrollButton">&gt;</button>
    </div>
    </div>
</div>
      <div class="container6">
      <div className="decorative-header-left">
                  <img  src='images/background-2 (1).svg' alt="Decorative element" />
                </div>

                <div className="decorative-header-right">
                  <img src='images/background-2 (1).svg' alt="Decorative element" />
                </div>
              <div class="image-container-6">
                  <img src="images/seated-06.jpg" alt="Yoga Instructors"/>
              </div>
              <div class="text-container">
                  <h1>Brought to you by two passionate instructors</h1>
                  <p>Georgia and Krishna met one day and discovered they both shared a deep and ever-growing love of yoga, sparking an idea for a vibrant community - and so Ekam Vida Yoga was born. Their mission is to unlock and share the benefits of yoga for people everywhere they go.</p>
                  <div class="read-more">
                      <a href="#">Read more <img src='images/arrow-right.svg' /></a>
                  </div>
              </div>
</div>
      
</>
  );
};

export default Home;
